/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import BranchContext from "."

const BranchProvider = ({ children }) => {
  const graphqlData = useStaticQuery(ALL_BRANCHES)
  const branchList = graphqlData.allLocalWpGraphQlBranches.nodes

  // Convenience variables
  const validBranches = useMemo(() => {
    const validBranches_temp = []
    for (let i = 0; i < branchList.length; ++i) {
      const branch_details = branchList[i]?.branch_details
      if (branch_details && "branchMoneyworksId" in branch_details) {
        const id = branch_details?.branchMoneyworksId
        if (id && typeof id == "string") {
          validBranches_temp.push(id)
        }
      }
    }
    return validBranches_temp
  }, [])
  const idToBranchMap = useMemo(() => {
    const idToBranchMap_temp = {}
    for (let i = 0; i < branchList.length; ++i) {
      const branch_details = branchList[i]?.branch_details
      if (branch_details && "branchMoneyworksId" in branch_details) {
        const id = branch_details?.branchMoneyworksId
        if (id && typeof id == "string") {
          idToBranchMap_temp[id] = branch_details
        }
      }
    }
    return idToBranchMap_temp
  }, [])

  // Stateful values
  const [branchSelectPopupOpen, setBranchSelectPopupOpen] = useState(false)
  const [selectedBranchObject, setSelectedBranchObject] = useState(() => {
    if (validBranches && typeof window != "undefined") {
      const new_branch = window.localStorage.getItem("selectedBranch")
      if (new_branch) {
        if (validBranches?.includes?.(new_branch)) {
          if (idToBranchMap && new_branch in idToBranchMap) {
            return idToBranchMap[new_branch]
          }
        }
      }
    }
  })
  const [selectedBranch, setSelectedBranchAbstract] = useState(() => {
    if (validBranches && typeof window != "undefined") {
      const new_branch = window.localStorage.getItem("selectedBranch")
      if (new_branch) {
        if (validBranches?.includes?.(new_branch)) {
          return new_branch
        }
      }
    }
  })

  // Handler for changing the selected branch
  const setSelectedBranch = (new_branch) => {
    if (typeof window != "undefined") {
      if (new_branch) {
        if (validBranches?.includes?.(new_branch)) {
          window.localStorage.setItem("selectedBranch", new_branch)
          setSelectedBranchAbstract(new_branch)
          if (idToBranchMap && new_branch in idToBranchMap) {
            setSelectedBranchObject(idToBranchMap[new_branch])
          } else {
            setSelectedBranchObject(null)
          }
        }
      }
    }
  }

  return (
    <BranchContext.Provider
      value={{
        selectedBranch,
        setSelectedBranch,
        selectedBranchObject,
        branchSelectPopupOpen,
        setBranchSelectPopupOpen,
        branchList,
        idToBranchMap,
      }}
    >
      {children}
    </BranchContext.Provider>
  )
}

export default BranchProvider

const ALL_BRANCHES = graphql`
  {
    allLocalWpGraphQlBranches {
      nodes {
        branch_details {
          branchName
          address
          operatingHours
          latitude
          longitude
          branchMoneyworksId
          image {
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 180, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
