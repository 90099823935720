import React from "react"

const ProductContext = React.createContext({
  getSize: () => {},
  currentProduct: null,
  setCurrentProduct: () => {},
  stockByLocation: null,
})

export default ProductContext
