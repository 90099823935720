import { useEffect, useLayoutEffect, useContext, useState } from "react"
import { useIntercom } from "react-use-intercom"

import AuthContext from "../context/AuthContext"
import { rawCheckWindowSize, windowSizes } from "../utils/responsive"
import { getUser } from "./auth"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================windowSizes.tablet
export const runIntercomFixes = ({ location }) => {
  const { loggedInState } = useContext(AuthContext)
  const [needsPadding, setNeedsPadding] = useState(null)
  // Intercom functions
  const { boot, shutdown, update } = useIntercom()

  // This useLayoutEffect is used to monitor for screen resizes
  // and to update where necessary
  useLayoutEffect(() => {
    function windowResizeEventHandler() {
      setNeedsPadding(
        rawCheckWindowSize({
          maxWidth: windowSizes.mobileMedium,
          defaultRender: true,
        })
      )
    }
    if (typeof window != "undefined") {
      window.addEventListener("resize", windowResizeEventHandler)
      setNeedsPadding(
        rawCheckWindowSize({
          maxWidth: windowSizes.mobileMedium,
          defaultRender: true,
        })
      )
    }
    if (typeof window != "undefined") {
      return () =>
        typeof window != "undefined" &&
        window.removeEventListener("resize", windowResizeEventHandler)
    }
  }, [])

  // On every navigate or path change, check if Intercom should boot
  useLayoutEffect(() => {
    // Boot intercom when not on home page
    // if (location.pathname && location.pathname !== "/") {
    if (location.pathname) {
      boot()
    }
  }, [location])

  // Check if Intercom should hide on mobile checkout
  useEffect(() => {
    // Boot intercom when not on home page
    if (
      location.pathname &&
      location.pathname == "/checkout/" &&
      needsPadding
    ) {
      shutdown()
    }
  }, [location, needsPadding])

  // If user logs in, attach their data to Intercom
  useEffect(() => {
    if (loggedInState) {
      updateUserIntercomSettings(update)
    }
  }, [loggedInState])

  // If padding state changes, update intercom style
  useEffect(() => {
    if (needsPadding == null) {
      setNeedsPadding(
        rawCheckWindowSize({
          maxWidth: windowSizes.mobileMedium,
          defaultRender: true,
        })
      )
    }
    if (needsPadding !== null) {
      fixIntercomPadding(needsPadding)
    }
  }, [needsPadding])
}

export const updateUserIntercomSettings = (update) => {
  if (typeof window !== "undefined") {
    let userInfo = getUser() //get the logged in user
    let intercomSettings = {}

    // get the logged in user's hash. Without the hash use intercom as if the user
    // hasn't been logged in
    if (userInfo.user_intercom_hash) {
      intercomSettings.userHash = userInfo.user_intercom_hash
      if (userInfo.user_display_name) {
        intercomSettings.name = userInfo.user_display_name
      } else if (userInfo.name) {
        intercomSettings.name = userInfo.user_nicename
      }
      if (userInfo.user_email) {
        intercomSettings.email = userInfo.user_email
      }
      if (
        userInfo.tokenPayload &&
        userInfo.tokenPayload.data &&
        userInfo.tokenPayload.data.user &&
        userInfo.tokenPayload.data.user.id
      ) {
        intercomSettings.userId = userInfo.tokenPayload.data.user.id
      }
    } else {
      intercomSettings = {}
    }

    // Update intercom settings
    update(intercomSettings)
  }
}

// This functions fixes the Intercom CSS to move the icon out of the way on mobile
export const fixIntercomPadding = (needsPadding, retry = 5) => {
  let found = false
  if (document && document.body) {
    var intercomFrame = document.getElementsByClassName(
      "intercom-launcher-frame"
    )
    if (!intercomFrame || intercomFrame?.length < 1) {
      intercomFrame = document.getElementsByClassName(
        "intercom-lightweight-app-launcher"
      )
    }
    if (intercomFrame && intercomFrame.length > 0) {
      const parentClassList = intercomFrame[0].parentNode.classList
      if (parentClassList && parentClassList.length > 0) {
        const targetClassPrefix = "intercom-with-namespace-"
        const regex = new RegExp(`^${targetClassPrefix}\\w+$`)
        for (const className of parentClassList) {
          if (regex.test(className)) {
            intercomFrame[0].parentNode.style.bottom = needsPadding
              ? "70px"
              : "20px"
            found = true
          }
        }
      }

      // intercomFrame[0].style.bottom = needsPadding ? "70px" : "20px"
      // found = true
    }
  }
  if (!found && retry > 1) {
    setTimeout(() => fixIntercomPadding(needsPadding, retry - 1), 500)
  }
}

export const onIntercomClosed = () => {
  fixIntercomPadding(
    rawCheckWindowSize({
      maxWidth: windowSizes.mobileMedium,
      defaultRender: true,
    })
  )
}
