import { getUser } from "./auth"
import {
  slugifiedSizeSplit,
  sizeBeautify,
  slugify,
} from "../lib/stringHandling"
import { getUtm, setUtm } from "../lib/util"
// browser check
const isBrowser = () => typeof window !== "undefined"

// export const gtmClearEcommerceObject = () => {
//   const eventName = "Clear Ecommerce Object"
//   try {
//     if (typeof window !== 'undefined' && window.dataLayer) {
//       dataLayer.push({ ecommerce: null })
//     } else {
//       process.env.GATSBY_GTM_DEBUG === "true" &&
//       console.log(`GTM: [${eventName}] FAILED, analytics function not valid`)
//     }
//   } catch {
//     process.env.GATSBY_GTM_DEBUG === "true" &&
//     console.log(`GTM: [${eventName}] FAILED, exception thrown`)
//   }
// }

/**
 * Track view product
 */
export const gtmProductViewed = (product) => {
  var payload = {
    currency: "ZAR",
    value: product.price, // Price after multiplied by quantity
    items: [
      {
        product_id: getProductId(product),
        sku: product.sku,
        item_id: product.sku,
        item_name: product.name,
        affiliation: process.env.GATSBY_WEBSITE_TITLE,
        item_brand: getProductBrand(product),
        item_category: getProductCategory(product),
        item_variant: product.size,
        price: product.price,
        quantity: 1,
        url: getProductUrl(product),
        image_url: product.product_image,
      },
    ],
  }
  payload = { ecommerce: payload }
  gtmEcommerceTrack("view_item", payload) // Send payload
}

/**
 * Track view cart
 */
export const gtmViewCart = (cartContents) => {
  let cartTotal = 0
  if (Array.isArray(cartContents) && cartContents.length > 0) {
    cartContents.map((product) => {
      if (!product.bundled_by) {
        cartTotal += product.line_total
      }
    })
  }
  var payload = {
    cart_id:
      Array.isArray(cartContents) &&
      cartContents.length > 0 &&
      cartContents[0].cart_hash,
    currency: "ZAR",
    value: cartTotal,
    items: [],
  }
  if (Array.isArray(cartContents)) {
    payload.items = cartContents.map((product, position) => ({
      product_id: getProductId(product),
      sku: product.sku,
      item_id: product.sku,
      item_name: product.product_title,
      affiliation: process.env.GATSBY_WEBSITE_TITLE,
      coupon: getProductCoupon(product),
      index: position + 1,
      item_brand: getProductBrand(product),
      item_category: getProductCategory(product),
      item_variant: getProductVariant(product),
      price: product.unit_price,
      quantity: product.quantity,
      url: getProductUrl(product),
      image_url: product.product_image,
    }))
  }
  payload = { ecommerce: payload }
  gtmEcommerceTrack("view_cart", payload)
}

/**
 * Add to cart
 */
export const gtmAddToCart = (product, quantity) => {
  var payload = {
    currency: "ZAR",
    value: product.line_total,
    cart_id: product.cart_hash,
    items: [
      {
        product_id: getProductId(product),
        sku: product.sku,
        item_id: product.sku,
        item_name: product.product_title,
        affiliation: process.env.GATSBY_WEBSITE_TITLE,
        coupon: getProductCoupon(product),
        item_brand: getProductBrand(product),
        item_category: getProductCategory(product),
        item_variant: getProductVariant(product),
        price: product.unit_price,
        quantity,
        url: getProductUrl(product),
        image_url: product.product_image,
      },
    ],
  }
  payload = { ecommerce: payload }
  gtmEcommerceTrack("add_to_cart", payload)
}

/**
 * Remove from cart
 */
export const gtmRemoveFromCart = (product, quantity) => {
  var payload = {
    cart_id: product.cart_hash,
    currency: "ZAR",
    value: product.line_total,
    items: [
      {
        product_id: getProductId(product),
        sku: product.sku,
        item_id: product.sku,
        item_name: product.product_title,
        affiliation: process.env.GATSBY_WEBSITE_TITLE,
        coupon: getProductCoupon(product),
        item_brand: getProductBrand(product),
        item_category: getProductCategory(product),
        item_variant: getProductVariant(product),
        price: product.unit_price,
        quantity,
        url: getProductUrl(product),
        image_url: product.product_image,
      },
    ],
  }
  payload = { ecommerce: payload }
  gtmEcommerceTrack("remove_from_cart", payload)
}

/**
 * Begin Checkout
 */
export const gtmCheckoutStarted = (cartContents, cartMeta) => {
  var payload = {
    currency: "ZAR",
    value: parseFloat(cartMeta.total),
    revenue: parseFloat(cartMeta.subtotal), // this total excludes shipping and tax
    shipping: parseFloat(cartMeta.shipping_total),
    tax: parseFloat(cartMeta.total_tax),
    discount: safelyAddNumbers(cartMeta.discount_total, cartMeta.discount_tax),
    coupon: cartContents.length > 0 ? getProductCoupon(cartContents[0]) : "",
    items: [],
  }
  if (Array.isArray(cartContents)) {
    payload.items = cartContents.map((product, position) => ({
      product_id: getProductId(product),
      sku: product.sku,
      item_id: product.sku,
      item_name: product.product_title,
      affiliation: process.env.GATSBY_WEBSITE_TITLE,
      coupon: getProductCoupon(product),
      index: position + 1,
      item_brand: getProductBrand(product),
      item_category: getProductCategory(product),
      item_variant: getProductVariant(product),
      price: product.unit_price,
      quantity: product.quantity,
      url: getProductUrl(product),
      image_url: product.product_image,
    }))
  }
  payload = { ecommerce: payload }
  gtmEcommerceTrack("begin_checkout", payload)
}

/**
 * Checkout step started
 */
export const gtmCheckoutStepComplete = (
  cartOrOrder,
  cartMeta,
  step = 1,
  selectedPaymentCode = ""
) => {
  let eventName = ""
  var payload = {
    currency: "ZAR",
    value: parseFloat(cartMeta.total),
    coupon: cartOrOrder.length > 0 ? getProductCoupon(cartOrOrder[0]) : "",
    items: [],
  }
  if (step == 1) {
    // Handle Step 1 - Shipping Info
    eventName = "add_shipping_info"
    const shippingClass = cartOrOrder[0].shipping_class.name
    payload.shipping_tier = shippingClass
  } else if (step == 2) {
    // Handle Step 2 - Payment Info
    eventName = "add_payment_info"
    payload.payment_type = selectedPaymentCode
  }
  if (Array.isArray(cartOrOrder)) {
    payload.items = cartOrOrder.map((product, position) => ({
      product_id: getProductId(product),
      sku: product.sku,
      item_id: product.sku,
      item_name: product.product_title,
      affiliation: process.env.GATSBY_WEBSITE_TITLE,
      coupon: getProductCoupon(product),
      index: position + 1,
      item_brand: getProductBrand(product),
      item_category: getProductCategory(product),
      item_variant: getProductVariant(product),
      price: product.unit_price,
      quantity: product.quantity,
      url: getProductUrl(product),
      image_url: product.product_image,
    }))
  }
  payload = { ecommerce: payload }
  gtmEcommerceTrack(eventName, payload)
}

/**
 * Order Complete
 */
export const gtmPurchaseComplete = (order) => {
  if (!order) {
    process.env.GATSBY_GTM_DEBUG === "true" &&
      console.log(`GTM: [gtmPurchaseComplete] no order provided`)
    return
  }
  var payload = {
    checkout_id: order.cart_hash || "",
    order_id: order.order_number || "",
    transaction_id: order.order_number || "",
    value: parseFloat(order.total),
    tax: parseFloat(order.total_tax),
    shipping: parseFloat(order.shipping_total),
    currency: "ZAR",
    coupon: Array.isArray(order.coupons) ? order.coupons.join("+") : "",
    discount: parseFloat(order.discount_total) + parseFloat(order.discount_tax),
    items: [],
  }
  if (Array.isArray(order.line_items)) {
    payload.items = order.line_items.map((product, position) => ({
      product_id: getProductId(product),
      sku: product.sku,
      item_id: product.sku,
      item_name: product.title,
      affiliation: process.env.GATSBY_WEBSITE_TITLE,
      index: position + 1,
      item_brand: getProductBrand(product),
      item_category: getProductCategory(product),
      item_variant: getProductVariant(product),
      price: product.total,
      quantity: product.quantity,
      url: getProductUrl(product),
      image_url: product.product_image,
    }))
  }
  payload = { ecommerce: payload }
  var userData = getUserDataPayload()
  var enhanced_conversion_data = {
    email: userData.email,
  }
  payload.enhanced_conversion_data = enhanced_conversion_data
  gtmEcommerceTrack("purchase", payload)
}

/**
 * Global Fire tracking event
 * @param eventName
 * @param payload
 */
const gtmEcommerceTrack = (eventName, payload) => {
  if (!isBrowser()) return
  // Console log data that will be sent
  process.env.GATSBY_GTM_DEBUG === "true" &&
    console.log(`GTM: [${eventName}] Trying to fire event`)
  // Send data
  try {
    if (typeof window !== "undefined" && window.dataLayer) {
      // if (typeof window !== 'undefined') {
      process.env.GATSBY_GTM_DEBUG === "true" &&
        console.log(`GTM FIRING: [${eventName}] payload = `, payload)
      window.gtag("event", eventName, payload)
      // window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      // window.dataLayer.push({ event: eventName, ecommerce: payload })
    } else {
      process.env.GATSBY_GTM_DEBUG === "true" &&
        console.log(`GTM: [${eventName}] FAILED, analytics function not valid`)
    }
  } catch {
    process.env.GATSBY_GTM_DEBUG === "true" &&
      console.log(`GTM: [${eventName}] FAILED, exception thrown`)
  }
}

export const gtmIdentify = () => {
  if (!isBrowser()) return

  const payload = getUserDataPayload()

  process.env.GATSBY_GTM_DEBUG === "true" &&
    console.log(`GTM: [Identify] payload ${payload.userId}`)

  try {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        user_id: payload.userId,
        email: payload.email,
        name: payload.name,
      })
    } else {
      process.env.GATSBY_GTM_DEBUG === "true" &&
        console.log("GTM: [Identify] FAILED, analytics function not valid")
    }
  } catch {
    process.env.GATSBY_GTM_DEBUG === "true" &&
      console.log("GTM: [Identify] FAILED, exception thrown")
  }
}

/**
 * Helper function: Get user Data
 */
const getUserDataPayload = () => {
  // Extract logged in user data from localstorage
  const user = getUser()
  if (!user) {
    process.env.GATSBY_GTM_DEBUG === "true" &&
      console.log(`GTM: [gtmIdentify] FAILED user not logged in`)
    return {}
  }
  return {
    email: user.user_email,
    name: user.user_display_name,
    userId: user?.tokenPayload?.data?.user?.id,
  }
}

/**
 * Helper function: Get Product ID
 */
const getProductId = (product) => {
  // Attach Product ID
  if (product.tracking_id && product.tracking_id != 0) {
    return product.tracking_id
  } else if (product.variation_id && product.variation_id != 0) {
    return product.variation_id
  } else if (product.product_id && product.product_id != 0) {
    return product.product_id
  } else if (product.wordpress_id && product.wordpress_id != 0) {
    return product.wordpress_id
  } else if (product.id && product.id != 0) {
    return product.id
  }
  return ""
}

/**
 * Helper function: Get Product Category
 */
const getProductCategory = (product) => {
  // Attach Root parent category
  if (product.categories && product.categories.length > 0) {
    const rootCat = product.categories.find(
      (ct) =>
        (ct.wordpress_parent_id === 0 || ct.parent === 0) &&
        ct.slug !== "all-brands"
    )
    if (rootCat && rootCat.name) {
      return rootCat.name
    }
  }
  return "Product"
}

/**
 * Helper function: Get Product Brand
 */
const getProductBrand = (product) => {
  // Attach Brand
  if (product.categories && product.categories.length > 0) {
    const brandCat = product.categories.find(
      (ct) => ct.wordpress_parent_id === 393 || ct.parent === 393 // WordPress ID of the "All Brands" category
    )
    if (brandCat && brandCat.name) {
      return brandCat.name
    }
  }
  return ""
}

/**
 * Helper function: Get Product Variant
 */
const getProductVariant = (product) => {
  // Attach Variant (size)
  if (product.size) {
    const size = sizeBeautify(slugifiedSizeSplit(product.size))
    if (Array.isArray(size) && size.length > 0) {
      return size[0]
    } else if (size) {
      return size
    }
  }
  return ""
}

/**
 * Helper function: Get Product Coupon
 */
const getProductCoupon = (product) => {
  // Attach Coupon
  const coupon = Object.keys(product.coupons)
  return coupon.length > 0 ? coupon[0] : ""
}

/**
 * Helper function: Get Product URL
 */
const getProductUrl = (product) => {
  // Attach product URL
  let url = product.product_title
    ? `${process.env.GATSBY_FRONTEND_URL}/product/${slugify(
        product.product_title
      )}`
    : ""
  url = url
    ? url
    : product.title
    ? `${process.env.GATSBY_FRONTEND_URL}/product/${slugify(product.title)}`
    : ""
  return url
}

/**
 * Helper function: Get Cart Hash ID
 */
const getCartHash = (cartOrOrder) => {
  // Get cart_hash from either cart or order object
  if (
    Array.isArray(cartOrOrder) &&
    cartOrOrder.length > 0 &&
    cartOrOrder[0].cart_hash
  ) {
    return cartOrOrder[0].cart_hash
  }
  if (cartOrOrder?.cart_hash) {
    return cartOrOrder.cart_hash
  }
  return ""
}

/**
 * Helper function: Get Order Payment Method
 */
const getOrderPaymentMethod = (cartOrOrder) => {
  // Extract payment method
  if (cartOrOrder?.payment_method_title) {
    return cartOrOrder.payment_method_title
  }
  return ""
}

/**
 * Helper function: Safely Add Numbers
 */
const safelyAddNumbers = (num1, num2) => {
  let num1parsed = parseFloat(num1)
  let num2parsed = parseFloat(num2)
  if (isNaN(num1parsed)) {
    num1parsed = 0
    process.env.GATSBY_GTM_DEBUG === "true" &&
      console.log(`GTM: safelyAddNumbers FAILED, ${num1} is NaN`)
  }
  if (isNaN(num2parsed)) {
    num2parsed = 0
    process.env.GATSBY_GTM_DEBUG === "true" &&
      console.log(`GTM: safelyAddNumbers FAILED, ${num2} is NaN`)
  }
  return num1parsed + num2parsed
}
