import React from "react"

const CartContext = React.createContext({
  cartId: null,
  cartDrawerOpen: false,
  cartCount: 0,
  cartState: "cart",
  cartContents: [],
  address: null,
  addToCart: () => {},
  updateCartCount: () => {},
  setCartState: () => {},
  setAddress: () => {},
})

export default CartContext
