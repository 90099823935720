import React from "react"

const BranchContext = React.createContext({
  selectedBranch: "",
  setSelectedBranch: () => {},
  branchSelectPopupOpen: false,
  setBranchSelectPopupOpen: () => {},
})

export default BranchContext
