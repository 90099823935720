import ky from "ky"

const kyWrapped = (
  url,
  { method = "get", logout = () => {}, timeout = 20000, ...content }
) => {
  const options = {
    retry: {
      limit: 3,
      statusCodes: [408, 413, 429, 502, 503, 504],
    },
    timeout,
  }
  switch (method) {
    case "get":
      return ky
        .get(url, { ...content, ...options })
        .json()
        .catch((error) => handleException(error, logout))
    case "post":
      return ky
        .post(url, { ...content, ...options })
        .json()
        .catch((error) => handleException(error, logout))
    case "put":
      return ky
        .put(url, { ...content, ...options })
        .json()
        .catch((error) => handleException(error, logout))
    case "delete":
      return ky
        .delete(url, { ...content, ...options })
        .json()
        .catch((error) => handleException(error, logout))
  }
  return
}

// This function will ensure that if the response has any decodable json, it will return it
// Otherwise it will return an empty object
const handleException = async (error, logout) => {
  if (error.response) {
    const errorContent = await error.response.json()
    console.error("Request error decoded: ", errorContent)
    if (
      errorContent.code.includes("invalid_token") ||
      errorContent.code.includes("no_user") ||
      errorContent.code.includes("bad_iss")
    ) {
      logout()
      throw { message: "You need to be logged in to perform this action" }
    }
    if (errorContent.message) {
      throw errorContent
    }
  } else {
    console.error("Request error cannot be decoded: ", error)
  }
  throw {}
}

// Endpoint to request product flatlist
export const checkFeverTreeId = (citizen_id, token) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/fevertree/id`
  return kyWrapped(url, {
    json: { citizen_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "post",
  })
}

// Endpoint to request product flatlist
export const getLatLngFromPostcode = (postcode) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/postal_code/position/${postcode}`
  return kyWrapped(url, {
    method: "get",
  })
}

// Endpoint to request product flatlist
export const getFlatlist = (page) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/compile/products?page=${page}`
  return kyWrapped(url, {
    method: "post",
  })
}

// Endpoint to get a single Fever Tree instalment
export const requestFeverTreeInstalment = (price) => {
  price = parseInt(price)
  if (Number.isInteger(price)) {
    const url = `${process.env.GATSBY_WP_URL}/wp-json/fevertree_instalment/v1/instalments/${price}`
    return kyWrapped(url, {
      method: "get",
    })
  }
  console.warn("Tried to request FeverTree isntalment for invalid price", price)
  return Promise.resolve(false)
}

// Endpoint to request stock
export const requestFlatlistStock = (skus, branch) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/product-stock`
  return kyWrapped(url, {
    json: {
      skus,
      branch,
    },
    method: "post",
  })
}

// Save Slumberland Competition Data
export const saveSlumberlandCompetitionData = (formValues) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/slumberland-competition-save-data`
  const config = {
    json: {
      formValues,
    },
    method: "post",
  }
  return kyWrapped(url, config)
}

// Endpoint to request stock
export const requestStock = (productId) => {
  productId = parseInt(productId)
  if (Number.isInteger(productId)) {
    const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/product-stock/${productId}`
    return kyWrapped(url, {
      method: "get",
    })
  }
  console.warn("Tried to request stock for invalid product ID", productId)
  return Promise.resolve(false)
}

// Endpoint to verify zeropay payments
export const checkZeropayReference = (zeropayReference) => {
  if (zeropayReference && zeropayReference !== "") {
    const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/check-zeropay-reference/${zeropayReference}`
    return kyWrapped(url, {
      method: "get",
    })
  }
  return Promise.resolve(false)
}

export const postReview = (review, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/review/${review.id}`
  let headers = {}
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, {
    headers,
    json: review,
    method: "post",
    logout,
  })
}

export const clearGuestCartTracking = (pushNotificationEndpoint) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/cart/clear_tracking`
  return kyWrapped(url, {
    json: {
      pushNotificationEndpoint,
    },
    throwHttpErrors: false,
    method: "delete",
  })
}

export const login = (email, password) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/jwt-auth/v1/token`
  return kyWrapped(url, {
    json: {
      username: email,
      password,
    },
    throwHttpErrors: false,
    method: "post",
  })
}

export const getOrder = (orderId, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/my-orders/${orderId}`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

// Function to fetch all orders by through heroku mediator
export const getAllOrders = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/my-orders`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

export const getLatestOrder = (customerId, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wc/v3/orders?customer=${customerId}&per_page=1&page=1`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

// "values" should contain at least values.email and values.password
export const register = (
  email,
  password,
  first_name = "",
  last_name = "",
  bday = "",
  opt_out = false,
  account_type = "",
  business_registration_number = "",
  business_name = "",
  contact_number = "",
  captchaResponse = ""
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/register`
  return kyWrapped(url, {
    json: {
      first_name,
      last_name,
      email,
      password,
      captchaResponse,
      birthday: bday,
      account_type,
      business_registration_number,
      business_name,
      contact_number,
      retention_science_opt_out: opt_out,
    },
    method: "post",
  })
}

// ****************************************************
// Cart requests
// ****************************************************
// **** Get cart contents, meta, shipping
export const getCartRequest = (user, logout = () => {}) => {
  const config = {
    credentials: "include",
    method: "get",
    logout,
  }
  // thumb true is added as query instead of json because fetch does not allow a body with a GET request
  let url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/cart`
  if (user) {
    if (user.token) {
      config.headers = {
        Authorization: `Bearer ${user.token}`,
      }
    }
  }
  return kyWrapped(url, config)
}
// **** Add an item to cart
export const postCartItemRequest = (
  postCartItemStruct,
  token = null,
  logout = () => {}
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/cart/add`
  const config = {
    json: postCartItemStruct,
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}
// **** Remove an item from cart
export const deleteCartItemRequest = (
  product,
  token = null,
  logout = () => {},
  pushNotificationEndpoint
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/cart/remove`
  const config = {
    json: {
      cart_item_key: product.key,
      pushNotificationEndpoint,
    },
    credentials: "include",
    method: "delete",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}
// **** Update item in cart
export const updateCartItemRequest = (
  product,
  quantity,
  token = null,
  logout = () => {},
  pushNotificationEndpoint
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/cart/update`
  const config = {
    json: {
      cart_item_key: product.key,
      quantity,
      pushNotificationEndpoint,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}
// **** Clear cart
export const clearCartRequest = ({
  token = null,
  logout = () => {},
  pushNotificationEndpoint,
}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/cart/clear`
  const config = {
    json: {
      pushNotificationEndpoint,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const getCustomer = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/my-address`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

export const getCountries = (token, logout = () => {}, country_code = "") => {
  const cc_url = country_code ? `/${country_code}` : ``
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/countries${cc_url}`
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  }
  return kyWrapped(url, config)
}

export const updateCustomer = (address, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/my-address`
  // eslint-disable-next-line camelcase
  const { ...putBody } = address
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: putBody,
    method: "post",
    logout,
  })
}

// DEPRECATED: Orders get created by createPayment
export const createOrder = (cartMeta, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/order`
  return kyWrapped(url, {
    json: {
      ...cartMeta,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    logout,
  })
}

export const createPayment = (
  paymentObject,
  token,
  logout = () => {},
  pushNotificationEndpoint
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/payment`
  return kyWrapped(url, {
    json: {
      ...paymentObject,
      pushNotificationEndpoint,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 30000, // Usually we don't need this much time. But Fever Tree is sometimes really slow causing timeouts
    method: "post",
    logout,
  })
}

// Post a coupon code to the cart
export const postPromoCoupon = (coupon, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/cocart/v1/coupon`
  const config = {
    json: {
      coupon,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

// Delete a coupon code from the cart
export const deletePromoCoupon = (coupon, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/cocart/v1/coupon`
  const config = {
    json: {
      coupon,
    },
    credentials: "include",
    method: "delete",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const postFeverTreeApplied = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/fevertree/applied`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 30000, // Usually we don't need this much time. But Fever Tree is sometimes really slow causing timeouts
    method: "post",
    logout,
  })
}

export const postFeverTreeOtp = (otpObject, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/fevertree/submit`
  return kyWrapped(url, {
    json: {
      ...otpObject,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 30000, // Usually we don't need this much time. But Fever Tree is sometimes really slow causing timeouts
    method: "post",
    logout,
  })
}

export const requestPassword = (email) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/autres/init`
  return kyWrapped(url, {
    json: { user_login: email },
    method: "post",
  })
}

export const submitPassword = (password, token) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/autres/hit`
  return kyWrapped(url, {
    json: {
      token,
      password,
    },
    method: "post",
  })
}

export const updateDonateMyBedOrderMeta = (order_id, donate_old_bed) => {
  if (order_id !== null && donate_old_bed !== null) {
    const url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/update-order-donate-old-bed/${order_id}/${donate_old_bed}`
    const config = {
      method: "get",
    }
    return kyWrapped(url, config)
  }
  return Promise.resolve(false)
}

export const getShippingMethods = (token, method = "", branch = "") => {
  let url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/get-shipping-methods-array`
  if (method !== "" || branch !== "") {
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: {
      method: method,
      branch: branch,
    },
    method: "post",
  }
  return kyWrapped(url, config)
}

export const setSelectedShippingMethod = (token, selectedMethod) => {
  let url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/set-user-selected-shipping-method`
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: {
      method: selectedMethod,
    },
    method: "post",
  }
  return kyWrapped(url, config)
}

export const setUserSelectedBranch = (token, selectedBranch) => {
  let url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/set-user-selected-branch`
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: {
      branch: selectedBranch,
    },
    method: "post",
  }
  return kyWrapped(url, config)
}

export const getProductStockLevel = (product_id) => {
  let url = `${process.env.GATSBY_WP_URL}/wp-json/restpayment/v1/get-product-stock-level/${product_id}`
  const config = {
    method: "get",
  }
  return kyWrapped(url, config)
}
