/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import ProductContext from "."

const ProductProvider = ({ children }) => {
  const [currentProduct, setCurrentProduct] = useState(null)

  return (
    <ProductContext.Provider
      value={{
        currentProduct,
        setCurrentProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export default ProductProvider
