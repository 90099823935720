import RootElementWrapper from "./src/utils/wrapPageElement"

export const wrapRootElement = RootElementWrapper

export const shouldUpdateScroll = ({ routerProps: { location }, pathname }) => {
  if (location && location.state) {
    const { state } = location
    const { shopScroll } = state
    if (shopScroll) {
      const { offsetTop } = document.getElementById("filter-row-99")
      window.scrollTo(0, offsetTop)
      return false
    }
  }

  return true
}

export const onServiceWorkerUpdateReady = () => {
  if (typeof window != "undefined") {
    console.warn("This page is out of date. Reloading...")
    window.location.reload()
  }
}

export const onClientEntry = () => {
  if (process.env.GATSBY_ACTIVE_ENV == "development") {
    registerServiceWorker()
  }
}

export const registerServiceWorker = () => true
