import { useEffect, useRef } from "react"
import { isBrowser } from "../services/auth"
import { format } from "date-fns"
import { titleCase, replaceAll } from "./stringHandling"
import queryString from "query-string"

const shippingInfo = {
  "delayed-shipping": {
    textColor: "white",
    backgroundColor: "#2ecc71",
    deliveryTextColor: "#9ba5ae",
    delay: "6 - 10",
    text: "Standard Shipping",
    description: "This product will ship in an estimated ",
  },
  "fast-track-shipping": {
    textColor: "white",
    backgroundColor: "#0998d2",
    deliveryTextColor: "#0998d2",
    delay: "1 - 2",
    text: "Fast Shipping",
    description: "This product has fast shipping, estimated ",
  },
  fallback: {
    textColor: "white",
    backgroundColor: "green",
    deliveryTextColor: "#9ba5ae",
    delay: "3 - 5",
    text: "Available",
    description: "This product will ship in an estimated ",
  },
}

// Order we typically want dropdowns to appear in
export const sizeOrder = [
  "Single",
  "Single XL",
  "Single and XL",
  "Single/Single XL",
  "Three Quarter",
  "Three Quarter XL",
  "Three Quarter and XL",
  "Three Quarter/Three Quarter XL",
  "Double",
  "Double XL",
  "Double and XL",
  "Double/Double XL",
  "Queen",
  "Queen XL",
  "Queen and XL",
  "Queen/Queen XL",
  "King",
  "King XL",
  "King and XL",
  "King/King XL",
  "Super King",
]

export const shippingClassGet = (slug = "fallback") => {
  return shippingInfo[slug] || shippingInfo["fallback"] || {}
}

export const lookupShippingClass = ({ soh, eff }) => {
  if (soh > 1 && eff > 1) {
    return "fast-track-shipping"
  } else if (eff < 1) {
    return "delayed-shipping"
  }
  return ""
}

/*
 * determineShippingClass:

 * fallback_shipping_class is used if the shipping class can't be derived for the location

 * stock_by_location_array will only have 1 item for simple and variable products
 * stock_by_location_array will have 2 items for bundled products
 * stock_by_location_array = [{
 *  effective: <int>
 *  soh: <int>
 * }]
 
 * selectedBranch is a string containing the selected branch
 */
export const determineShippingClass = (
  fallback_shipping_class,
  stock_by_location_array,
  selectedBranch
) => {
  if (!Array.isArray(stock_by_location_array)) {
    stock_by_location_array = [stock_by_location_array]
  }
  if (selectedBranch) {
    let soh = null
    let eff = null
    let result_valid = true
    for (const stock_by_location of stock_by_location_array) {
      if (
        !selectedBranch ||
        !stock_by_location ||
        !(selectedBranch in stock_by_location)
      ) {
        result_valid = false
        return
      } else {
        const stock = stock_by_location[selectedBranch]
        if (!stock || !("soh" in stock) || !("effective" in stock)) {
          result_valid = false
          return
        } else {
          soh = soh === null ? stock.soh : Math.min(soh, stock.soh)
          eff = eff === null ? stock.effective : Math.min(eff, stock.effective)
        }
      }
    }
    if (result_valid) {
      return lookupShippingClass({ soh, eff })
    }
  }
  return fallback_shipping_class ?? null
}

export const getShippingClassInCart = (cartContents, item, selectedBranch) => {
  let shipping_class = false

  // If the item is a bundle, find the mattress and use that shipping class and stock only
  if (item.bundled_items) {
    // Find the cart items that are in this bundle
    const stock_by_location_array = []
    let mattress_shipping_class = null
    for (const ch_item of cartContents) {
      if (item.bundled_items.includes(ch_item.key)) {
        // Check if the item is a mattress
        if (ch_item.categories.some((cat) => cat.slug == "mattresses")) {
          stock_by_location_array.push(ch_item?.stock_by_location)
          // Check if the item has a shipping_class
          if (ch_item.shipping_class) {
            mattress_shipping_class = ch_item?.shipping_class?.slug
          }
        }
      }
    }
    shipping_class = determineShippingClass(
      mattress_shipping_class,
      stock_by_location_array,
      selectedBranch
    )
  } else {
    shipping_class = determineShippingClass(
      item?.shipping_class?.slug,
      [item?.stock_by_location],
      selectedBranch
    )
  }

  return shipping_class
}

const skuRegex = /(TMW\d+)/gi
export const extractSku = (sku) => {
  if (typeof sku == "string") {
    const capture = sku.match(skuRegex)
    if (Array.isArray(capture)) {
      return capture[0]
    }
    return capture
  }
  return null
}

const buildCrumbs = (product = {}, pathname = "") => {
  let categories = []
  if (product && product.categories && product.categories.length > 0) {
    categories = product.categories
  }
  categories = categories.filter((ct) => ct.slug !== "uncategorised") // don't use this category ever

  // Main first-crumb categories to consider
  const bedroomFurnitureCategory = categories.find(
    (ct) => ct.slug == "bedroom-furniture"
  )
  const bedroomAccessoriesCategory = categories.find(
    (ct) => ct.slug == "bedroom-accessories"
  )
  const beddingCategory = categories.find((ct) => ct.slug == "bedding")
  const allBrandsCategory = categories.find((ct) => ct.slug == "all-brands")

  // Other categories to consider (for naming conventions)
  const mattressCategory = categories.find((ct) => ct.slug == "mattresses")
  const bedsCategory = categories.find((ct) => ct.slug == "beds")

  // All remaining categories that are top level
  const remainingCategories = categories.filter(
    (ct) =>
      ct.slug !== "bedroom-furniture" &&
      ct.slug !== "bedroom-accessories" &&
      ct.slug !== "bedding" &&
      ct.slug !== "mattresses" &&
      ct.slug !== "beds" &&
      ct.slug !== "all-brands" &&
      ct.wordpress_parent_id === 0
  )

  // Crumb storage
  let firstCrumb = null
  let secondCrumb = null
  let thirdCrumb = null

  // Divan - Build bedding crumbs START
  if (!firstCrumb && beddingCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == beddingCategory.wordpress_id
    )
    if (childCat) {
      firstCrumb = beddingCategory
      secondCrumb = childCat
    }
    if (firstCrumb && secondCrumb) {
      if (isBrowser()) {
        var url = new URL(window.location.href)
        var search_params = new URLSearchParams(url.search)
        const current_size = search_params.get("size")
        if (current_size) {
          thirdCrumb = {
            name:
              titleCase(
                current_size.replace("-", " ").replace("xl", " Extra Length")
              ) +
              " " +
              secondCrumb.name,
            slug:
              current_size.replace("xl", "extra-length") +
              "-" +
              secondCrumb.slug,
          }
        }
      }

      // const remainingChildCats = categories.filter(
      //   (ct) =>
      //     ct.wordpress_id !== beddingCategory.wordpress_id &&
      //     ct.wordpress_parent_id !== beddingCategory.wordpress_id &&
      //     ct.wordpress_id !== secondCrumb.wordpress_id
      // )
      // if (isBrowser()) {
      //   var url = new URL(window.location.href)
      //   var search_params = new URLSearchParams(url.search)
      //   const size = search_params.get("size")
      //   if (remainingChildCats.length > 0) {
      //     const selectedChild = remainingChildCats.find(
      //       (ct) => ct.slug == size + "-flat-sheets"
      //     )
      //     if (selectedChild) {
      //       thirdCrumb = {
      //         name: `${selectedChild.name}`,
      //         slug: `${selectedChild.slug}`,
      //       }
      //     }
      //   }
      // }
    }
  }
  // Divan - Build bedding crumbs START

  // Build Bedroom Furniture crumbs
  if (!firstCrumb && bedroomFurnitureCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == bedroomFurnitureCategory.wordpress_id
    )
    if (childCat) {
      firstCrumb = bedroomFurnitureCategory
      secondCrumb = childCat
    }
    if (firstCrumb && secondCrumb) {
      if (secondCrumb.slug === "headboards") {
        if (isBrowser()) {
          var url = new URL(window.location.href)
          var search_params = new URLSearchParams(url.search)
          const current_size = search_params.get("size")
          if (current_size) {
            thirdCrumb = {
              name:
                titleCase(current_size.replace("-", " ")) + " Bed Headboards",
              slug: current_size + "-bed-headboards",
            }
          }
        }
      }
    }
  }

  // Build Bedroom Accessories crumbs
  if (!firstCrumb && bedroomAccessoriesCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == bedroomAccessoriesCategory.wordpress_id
    )
    if (childCat) {
      firstCrumb = bedroomAccessoriesCategory
      secondCrumb = childCat
    }
    if (firstCrumb && secondCrumb) {
      if (secondCrumb.name === "Mattress Protectors") {
        if (isBrowser()) {
          var url = new URL(window.location.href)
          var search_params = new URLSearchParams(url.search)
          const current_size = search_params.get("size")
          if (current_size) {
            thirdCrumb = {
              name:
                titleCase(current_size.replace("-", " ")) +
                " " +
                secondCrumb.name,
              slug: current_size + "-" + secondCrumb.slug,
            }
          }
        }
      } else {
        const remainingChildCats = categories.filter(
          (ct) =>
            ct.wordpress_id !== bedroomAccessoriesCategory.wordpress_id &&
            ct.wordpress_parent_id !==
              bedroomAccessoriesCategory.wordpress_id &&
            ct.wordpress_id !== secondCrumb.wordpress_id
        )
        if (remainingChildCats.length > 0) {
          const selectedChild = remainingChildCats.find(
            (ct) => ct.wordpress_parent_id === secondCrumb.wordpress_id
          )
          if (selectedChild) {
            thirdCrumb = {
              name: `${selectedChild.name}`,
              slug: `${selectedChild.slug}`,
            }
          }
        }
      }
    }
  }

  // DIVAN START - Build Mattress crumbs
  if (!firstCrumb && mattressCategory) {
    firstCrumb = mattressCategory

    if (isBrowser()) {
      var url = new URL(window.location.href)
      var search_params = new URLSearchParams(url.search)
      const current_size = search_params.get("size")
      if (current_size) {
        secondCrumb = {
          name:
            titleCase(
              current_size.replace("xl", "extra-length").replace("-", " ")
            ) + " Mattresses",
          slug: current_size.replace("xl", "extra-length") + "-mattresses",
        }
      }
    }

    // const remainingChildCats = categories.filter(
    //   (ct) => ct.wordpress_parent_id === mattressCategory.wordpress_id
    // )
    // if (isBrowser()) {
    //   var url = new URL(window.location.href)
    //   var search_params = new URLSearchParams(url.search)
    //   const size = search_params.get("size")
    //   if (remainingChildCats.length > 0) {
    //     const selectedChild = remainingChildCats.find(
    //       (ct) => ct.slug == size + "-mattresses"
    //     )
    //     if (selectedChild) {
    //       secondCrumb = {
    //         name: `${selectedChild.name}`,
    //         slug: `${selectedChild.slug}`,
    //       }
    //     }
    //   }
    // }
  }
  // DIVAN END - Build Mattress Crumbs

  // DIVAN START - Build Beds crumbs
  if (!firstCrumb && bedsCategory) {
    firstCrumb = bedsCategory

    if (isBrowser()) {
      var url = new URL(window.location.href)
      var search_params = new URLSearchParams(url.search)
      const current_size = search_params.get("size")
      if (current_size) {
        secondCrumb = {
          name: titleCase(current_size.replace("xl", "extra-length")) + " Beds",
          slug: current_size.replace("xl", "extra-length") + "-beds",
        }
      }
    }

    // const remainingChildCats = categories.filter(
    //   (ct) => ct.wordpress_parent_id === bedsCategory.wordpress_id
    // )
    // if (isBrowser()) {
    //   var url = new URL(window.location.href)
    //   var search_params = new URLSearchParams(url.search)
    //   const size = search_params.get("size")
    //   if (remainingChildCats.length > 0) {
    //     const selectedChild = remainingChildCats.find(
    //       (ct) => ct.slug == size.replace('xl', 'extra-length') + "-beds"
    //     )
    //     if (selectedChild) {
    //       secondCrumb = {
    //         name: `${selectedChild.name}`,
    //         slug: `${selectedChild.slug}`,
    //       }
    //     }
    //   }
    // }
  }
  // DIVAN END - Build Beds Crumbs

  // Build All Brands crumbs
  if (!firstCrumb && allBrandsCategory) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == allBrandsCategory.wordpress_id
    )
    if (childCat) {
      if (mattressCategory) {
        firstCrumb = allBrandsCategory
        secondCrumb = {
          name: `${childCat.name} Mattresses`,
          slug: `${childCat.slug}-mattresses`,
        }
      } else if (bedsCategory) {
        firstCrumb = allBrandsCategory
        secondCrumb = {
          name: `${childCat.name} Beds`,
          slug: `${childCat.slug}-beds`,
        }
      } else {
        firstCrumb = allBrandsCategory
        secondCrumb = childCat
      }
    }
  }
  if (!firstCrumb && remainingCategories.length > 0) {
    const childCat = categories.find(
      (ct) => ct.wordpress_parent_id == remainingCategories[0].wordpress_id
    )
    firstCrumb = remainingCategories[0]
    if (childCat) {
      secondCrumb = childCat
    }
  }

  // Build product crumb if categories are present
  if (product && product.wordpress_id) {
    let returnCrumbs = []
    if (firstCrumb) {
      returnCrumbs.push(firstCrumb)
      if (secondCrumb) {
        returnCrumbs.push(secondCrumb)
        if (thirdCrumb) {
          returnCrumbs.push(thirdCrumb)
        }
      }
    }

    return formatCrumbs(returnCrumbs)
  }

  // If we don't have any product category crumbs, built a breadcrumb using the URL path
  let pathCrumbs = []
  try {
    pathCrumbs = pathname.split("/").map((it, i) => {
      return {
        name: it,
        slug: it,
      }
    })
    // eslint-disable-next-line no-empty
  } catch {}
  return formatCrumbs(pathCrumbs)
}
const formatCrumbs = (array = []) => {
  // Build crumb label and URL
  let pathStore = ""
  const returnCrumbs =
    Array.isArray(array) &&
    array.length > 0 &&
    array
      .map((cr) => {
        const name = titleCase(replaceAll(cr.name, "-", " ")).replace(
          " Cm ",
          "cm "
        )
        pathStore = `${pathStore}${cr.slug}/`
        return {
          name,
          link: pathStore,
        }
      })
      .filter((i) => i && i.name && i.name !== "Category") // remove blanks
  if (returnCrumbs && returnCrumbs.length > 0) {
    return returnCrumbs
  }
  // Fallback crumbs
  return [
    {
      name: "Shop",
      link: `/shop/`,
    },
  ]
}

const addSizeFormat = (size) => {
  switch (size) {
    case "single-extra-length":
      return "single-xl"
    case "three-quarter-extra-length":
      return "three-quarter-xl"
    case "double-extra-length":
      return "double-xl"
    case "queen-extra-length":
      return "queen-xl"
    case "king-extra-length":
      return "king-xl"
    case "single-xl":
      return "single-extra-length"
    case "three-quarter-xl":
      return "three-quarter-extra-length"
    case "double-xl":
      return "double-extra-length"
    case "queen-xl":
      return "queen-extra-length"
    case "king-xl":
      return "king-extra-length"
  }
  return null
}

const doQueryTest = (queryTest, callback) => {
  try {
    if (window.location.search.endsWith(queryTest)) {
      const removeQueryTestStrLen =
        window.location.search.length - queryTest.length
      history.replaceState(
        null,
        "",
        window.location.search.substring(0, removeQueryTestStrLen)
      )
      callback()
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

// This allows us to hide text behind "Loading" while the window is initialising
const ifWindowLoaded = (ret) => {
  return typeof window == "undefined" ? "LOADING" : ret
}

// Add VAT and format price
const addVatAndFormatPrice = (price) => {
  const intPrice = parseInt(price)
  if (!isNaN(intPrice)) {
    if (intPrice < 1) {
      return "Free"
    } else {
      const fullPrice = intPrice * 1.15
      return formatPrice(fullPrice)
    }
  } else {
    return "TBA"
  }
}

const formatDeliveryInfo = (shippingData) => {
  const par1 = `Within ${
    shippingData.radius_one
  }km (main cities): ${addVatAndFormatPrice(shippingData.radius_one_cost)}<br/>`
  const par2 = `Between ${parseInt(shippingData.radius_one) + 1}-${
    shippingData.radius_two
  }km: ${addVatAndFormatPrice(shippingData.radius_two_cost)}<br/>`
  const par3 = `Between ${parseInt(shippingData.radius_two) + 1}-${
    shippingData.radius_three
  }km: ${addVatAndFormatPrice(shippingData.radius_three_cost)}<br/>`
  const par4 = `Beyond ${
    parseInt(shippingData.radius_three) + 1
  }km: ${addVatAndFormatPrice(shippingData.radius_four_cost)}<br/>`
  return `${par1}${par2}${par3}${par4}`
}

const formatPrice = (number) => {
  const parsedNumber = parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  if ("0.00" != parsedNumber && "NaN" != parsedNumber) {
    return `R ${parsedNumber}`
  }
  return false
}

const timeStampedLog = (message) => {
  console.log(
    `${
      new Date().toISOString().match(/(\d\d:\d\d:\d\d\.\d\d\d\w)/)[0]
    } > ${message}`
  )
}
// Parse URL query values -----------------------------------------------------
const getQueryVariable = (variable, optCallback) => {
  if (typeof window !== "undefined") {
    const currentQuery = new URLSearchParams(window.location.search)
    const value = currentQuery.get(variable)
    if (value) {
      if (optCallback) optCallback(value)
      return value
    }
  }
  if (optCallback) optCallback(false)
  return false
}
const setQueryVariable = (variable, value) => {
  if (typeof window !== "undefined") {
    const currentQuery = new URLSearchParams(window.location.search)
    currentQuery.set(variable, value)
    window.location.search = currentQuery.toString()
  }
  return true
}

// partially applies arguments to function and returns a function waiting for the rest of the arguments
const curry =
  (f, arr = []) =>
  (...args) =>
    ((a) => (a.length === f.length ? f(...a) : curry(f, a)))([...arr, ...args])

const getPath = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
// safly acces path on object
const path = curry(getPath)

const getBackgroundFluid = path([
  "backgroundImage",
  "imageFile",
  "childImageSharp",
  "fluid",
])

const formatMoney = (number) =>
  "R " +
  parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")

const formatDateStr = (dateStr) =>
  isBrowser() ? format(new window.Date(dateStr), "MM LLL yy") : ""

// Create nice slugs from complex strings
const slugify = (string) => {
  const a =
    "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;"
  const b =
    "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  try {
    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
    // eslint-disable-next-line no-empty
  } catch {}
}

// Format float to currncy format (commas seperating thousands and 2 decimals)
const formatCurrencyInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

// Backwards compatible => these functions will either extract old gatsby image or new gatsby image
const getFluid = (node) =>
  path(["imageFile", "childImageSharp", "fluid"], node) ||
  path(["imageFile", "localFile", "childImageSharp", "fluid"], node) ||
  path(["featuredImage", "imageFile", "childImageSharp", "fluid"], node) ||
  path(
    ["featuredImage", "imageFile", "localFile", "childImageSharp", "fluid"],
    node
  )
export const getImage = (node) =>
  path(["childImageSharp", "gatsbyImageData"], node) ||
  path(["file", "childImageSharp", "gatsbyImageData"], node) ||
  path(["file", "localFile", "childImageSharp", "gatsbyImageData"], node) ||
  path(["imageFile", "childImageSharp", "gatsbyImageData"], node) ||
  path(
    ["imageFile", "localFile", "childImageSharp", "gatsbyImageData"],
    node
  ) ||
  path(
    ["featuredImage", "imageFile", "childImageSharp", "gatsbyImageData"],
    node
  ) ||
  path(
    [
      "featuredImage",
      "imageFile",
      "localFile",
      "childImageSharp",
      "gatsbyImageData",
    ],
    node
  )

const fluid = (node) => getFluid(node) || getImage(node)

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
// Strip out our WP link to create a local path link
function CreateLocalLink(url) {
  if (url === "#") {
    return null
  }
  let newUri = url.replace(process.env.GATSBY_WP_URL, "")
  return newUri
}

const formatPhoneToLink = (str) => {
  if (!str.includes("(")) {
    return `<a href="tel:${str.replace(/ /g, "")}">${str}</a>`
  }
  const [number, label] = str.split("(")
  const labelWithSpan = "<span class='subtle'>(" + label + "</span>"
  const numberWithLink = `<a href="tel:${number.replace(
    / /g,
    ""
  )}">${number}</a>`
  return numberWithLink + labelWithSpan
}

const formatEmailToLink = (str) => `<a href="mailto:${str}">${str}</a>`

// prettier-ignore
const shorten = str => str.split(" ").slice(0, 30).join(" ").concat("...")
const isValid = (str) => str && str.length > 0
const stripHtml = (html) => html.replace(/(<([^>]+)>)/gi, "")
const stripAndShorten = (str) => shorten(stripHtml(str))

const createExcerpt = (excerpt, fallbackContent) =>
  stripAndShorten(
    isValid(excerpt) ? excerpt : isValid(fallbackContent) ? fallbackContent : ""
  )

const getIndexOfKey = (data, key) => {
  var i
  if (data && data.length) {
    for (i = 0; i < data.length; i++) {
      if (data[i].key == key) {
        return i
      }
    }
  }
  return null
}

/**
 * Get all utm params we stored in localstorage
 */
export const getUtm = () => {
  setUtm() // Capture current UTM parameters if available
  if (typeof window !== "undefined") {
    let ret = {}
    try {
      ret = {
        utm_campaign_json: JSON.parse(
          window.localStorage.getItem("utm_campaign_json")
        ),
        utm_campaign_raw: window.localStorage.getItem("utm_campaign_raw"),
      }
    } catch (e) {
      console.log("UTM get Error: JSON.parse failed")
    }
    return ret
  }
  console.log("UTM get Error: window not defined")
  return {}
}
/**
 * Set all utm params we stored in localstorage
 */
export const setUtm = () => {
  if (typeof window !== "undefined") {
    if (window.location.search) {
      // Save UTM parameters to localstorage
      try {
        const campaignData = utm(window.location.search)
        // Only store UTM params if they are present. Don't overwrite the old one if we don't have new ones
        if (
          campaignData &&
          Object.keys(campaignData) &&
          Object.keys(campaignData).length > 0
        ) {
          window.localStorage.setItem(
            "utm_campaign_json",
            JSON.stringify(campaignData)
          )
          window.localStorage.setItem(
            "utm_campaign_raw",
            window.location.search
          )
        }
      } catch (e) {
        console.log("UTM set Error: URL parse failed")
      }
    }
  } else {
    console.log("UTM set Error: window not defined")
  }
}
/**
 * Get all utm params from the given `querystring`
 * Copied directly from Segment IO github:
 * https://github.com/segmentio/analytics.js/blob/7dda4259d40375666ea6fd166d51b208d79c38ec/analytics.js#L18707
 */
export const utm = (query) => {
  if ("?" == query.charAt(0)) query = query.substring(1)
  var query = query.replace(/\?/g, "&")
  var params = queryString.parse(query)
  var param
  var ret = {}

  for (var key in params) {
    if (~key.indexOf("utm_")) {
      param = key.substr(4)
      if ("campaign" == param) param = "name"
      ret[param] = params[key]
    }
  }

  return ret
}

export const urlWithParamValue = (key, value) => {
  // Initialise URL values
  let pathname = ""
  let pathQueries = {}
  if (typeof window !== "undefined") {
    pathname = window.location.pathname
    pathQueries = queryString.parse(window.location.search)
  }

  return `${pathname}?${queryString.stringify({
    ...pathQueries,
    [key]: value,
  })}`
}

/*
 *  This function calculates the approximate distance between 2 pins
 *  The results are an approximation of the distance in km
 *  The result is calculated by taking the coordinates as 2 dimensional points
 *  and calculating the pythagorean distance between them
 *
 * The result is multiplied by 120 as fudge factor
 *
 *  pin1 = {
 *    latitude,
 *    longitude
 *  }
 *
 *  pin2 = {
 *    latitude,
 *    longitude
 *  }
 *
 *  Return null on error
 */
export const calcCoordDistanceApprox = (pin1, pin2) => {
  const lat1 = parseFloat(pin1?.latitude)
  const lng1 = parseFloat(pin1?.longitude)
  const lat2 = parseFloat(pin2?.latitude)
  const lng2 = parseFloat(pin2?.longitude)
  if (!isNaN(lat1) && !isNaN(lng1) && !isNaN(lat2) && !isNaN(lng2)) {
    const latDist = Math.pow(lat1 - lat2, 2)
    const lngDist = Math.pow(lng1 - lng2, 2)
    return Math.round(Math.sqrt(latDist + lngDist) * 120)
  }
  return Infinity
}

export {
  createExcerpt,
  path,
  curry,
  getBackgroundFluid,
  useInterval,
  slugify,
  getFluid,
  CreateLocalLink,
  formatPhoneToLink,
  formatEmailToLink,
  formatDateStr,
  fluid,
  formatCurrencyInt,
  formatMoney,
  formatDeliveryInfo,
  getQueryVariable,
  setQueryVariable,
  getIndexOfKey,
  timeStampedLog,
  ifWindowLoaded,
  formatPrice,
  doQueryTest,
  buildCrumbs,
  addSizeFormat,
}
