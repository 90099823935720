/**
 * Constants, moved to a different file to keep things clean
 */
import {
  unescapeCharactersMap,
  characters,
  diacritics,
  MAX_SAFE_INTEGER,
  REGEXP_EXTENDED_ASCII,
  REGEXP_NON_LATIN,
  REGEXP_LATIN_WORD,
  REGEXP_WORD,
  REGEXP_COMBINING_MARKS,
} from "./stringHandlingConsts"

/**
 * Functions, these are exported and are the functions used in the codebase
 */

// Convert array of sizes to proper looking name: ['single', 'single-xl'] => 'Single/Single-XL'
export const sizeBeautify = (sizeArr) => {
  if (Array.isArray(sizeArr) && sizeArr.length > 0) {
    return sizeArr
      .map((sz) =>
        sz
          .split("-")
          .map((word) => (word == "xl" ? "XL" : titleCase(word)))
          .join(" ")
      )
      .sort((a, b) => a.length - b.length)
      .join("/")
  }
  return typeof sizeArr == "string" ? sizeArr : ""
}

// Convert array of sizes to a single string: ['single', 'single-xl'] => 'single-single-xl'
export const singleStringSize = (sizeArr) => {
  if (Array.isArray(sizeArr)) {
    if (sizeArr.length > 1) {
      return sizeArr.sort((a, b) => a.length - b.length).join("-")
    } else if (sizeArr.length == 1) {
      return sizeArr[0]
    }
  }
  return typeof sizeArr == "string" ? sizeArr : ""
}

// Convert slugified size string to split size array
export const slugifiedSizeSplit = (size) => {
  const slugSize = lowerCase(size)
  switch (slugSize) {
    case "single-single-xl":
      return ["single", "single-xl"]
    case "three-quarter-three-quarter-xl":
      return ["three-quarter", "three-quarter-xl"]
    case "double-double-xl":
      return ["double", "double-xl"]
    case "queen-queen-xl":
      return ["queen", "queen-xl"]
    case "king-king-xl":
      return ["king", "king-xl"]
    default:
      return [slugSize]
  }
}

// Convert to TitleCase
export const titleCase = (subject, noSplit) => {
  const subjectString = coerceToString(subject)
  const noSplitArray = Array.isArray(noSplit) ? noSplit : []
  const wordsRegExp = REGEXP_EXTENDED_ASCII.test(subjectString)
    ? REGEXP_LATIN_WORD
    : REGEXP_WORD
  return subjectString.replace(wordsRegExp, function (word, index) {
    const isNoSplit =
      index > 0 && noSplitArray.indexOf(subjectString[index - 1]) >= 0
    return isNoSplit ? word.toLowerCase() : capitalize(word, true)
  })
}

// convert to snake_case
export const snakeCase = (subject) => {
  const subjectString = coerceToString(subject)
  if (subjectString === "") {
    return ""
  }
  return words(subjectString).map(lowerCase).join("_")
}

// convert to CAPITALIZED
export const capitalize = (subject, restToLower) => {
  let subjectString = coerceToString(subject)
  const restToLowerCaseBoolean = coerceToBoolean(restToLower)
  if (subjectString === "") {
    return ""
  }
  if (restToLowerCaseBoolean) {
    subjectString = subjectString.toLowerCase()
  }
  return subjectString.substr(0, 1).toUpperCase() + subjectString.substr(1)
}

// convert to slug-string
export const slugify = (subject) => {
  const subjectString = coerceToString(subject)
  if (subjectString === "") {
    return ""
  }
  const cleanSubjectString = latinise(subjectString).replace(
    REGEXP_NON_LATIN,
    "-"
  )
  return kebabCase(cleanSubjectString)
}

// convert to kebab-case
export const kebabCase = (subject) => {
  const subjectString = coerceToString(subject)
  if (subjectString === "") {
    return ""
  }
  return words(subjectString).map(lowerCase).join("-")
}

// convert to regular latin characters
export const latinise = (subject) => {
  const subjectString = coerceToString(subject)
  if (subjectString === "") {
    return ""
  }
  return subjectString
    .replace(REGEXP_NON_LATIN, getLatinCharacter)
    .replace(REGEXP_COMBINING_MARKS, removeCombiningMarks)
}

// replace all instances of a character
export const replaceAll = (subject, search, replace) => {
  const subjectString = coerceToString(subject)
  if (search instanceof RegExp) {
    if (search.flags.indexOf("g") === -1) {
      throw new TypeError("search argument is a non-global regular expression")
    }
    return subjectString.replace(search, replace)
  }
  const searchString = coerceToString(search)
  const isFunctionalReplace = typeof replace === "function"
  if (!isFunctionalReplace) {
    replace = coerceToString(replace)
  }
  const searchLength = searchString.length
  if (searchLength === 0) {
    return replaceAll(subject, /(?:)/g, replace)
  }
  const advanceBy = searchLength > 1 ? searchLength : 1
  const matchPositions = []
  let position = subjectString.indexOf(searchString, 0)
  while (position !== -1) {
    matchPositions.push(position)
    position = subjectString.indexOf(searchString, position + advanceBy)
  }
  let endOfLastMatch = 0
  let result = ""
  for (let i = 0; i < matchPositions.length; i++) {
    const position = matchPositions[i]
    let replacement = replace
    if (isFunctionalReplace) {
      replacement = coerceToString(
        replace.call(undefined, searchString, position, subjectString)
      )
    }
    result += subjectString.slice(endOfLastMatch, position) + replacement
    endOfLastMatch = position + searchLength
  }
  if (endOfLastMatch < subjectString.length) {
    result += subjectString.slice(endOfLastMatch)
  }
  return result
}

// replace the first instance of a character
export const replace = (subject, search, replace) => {
  const subjectString = coerceToString(subject)
  return subjectString.replace(search, replace)
}

// find index of a character
export const search = (subject, pattern, fromIndex) => {
  const subjectString = coerceToString(subject)
  const fromIndexNumber = isNil(fromIndex)
    ? 0
    : clipNumber(toInteger(fromIndex), 0, subjectString.length)
  let matchIndex = subjectString.substr(fromIndexNumber).search(pattern)
  if (matchIndex !== -1 && !isNaN(fromIndexNumber)) {
    matchIndex += fromIndexNumber
  }
  return matchIndex
}

// check if subject includes a string
export const includes = (subject, search, position) => {
  const subjectString = coerceToString(subject)
  const searchString = toString(search)
  if (searchString === null) {
    return false
  }
  if (searchString === "") {
    return true
  }
  position = isNil(position)
    ? 0
    : clipNumber(toInteger(position), 0, subjectString.length)
  return subjectString.indexOf(searchString, position) !== -1
}

// split a string according to separator
export const split = (subject, separator, limit) => {
  const subjectString = coerceToString(subject)
  return subjectString.split(separator, limit)
}

// unescape html
export const unescapeHtml = (subject) => {
  const subjectString = coerceToString(subject)
  return characters.reduce(reduceUnescapedString, subjectString)
}

/**
 * Helper Functions, only used in this file and not exported
 */
const coerceToBoolean = (value, defaultValue = false) => {
  if (isNil(value)) {
    return defaultValue
  }
  return Boolean(value)
}
const coerceToString = (value, defaultValue = "") => {
  if (isNil(value)) {
    return defaultValue
  }
  if (isString(value)) {
    return value
  }
  return String(value)
}
const isNil = (value) => {
  return value === undefined || value === null
}
const isString = (subject) => {
  return typeof subject === "string"
}
const toString = (value) => {
  if (isNil(value)) {
    return null
  }
  if (isString(value)) {
    return value
  }
  return String(value)
}
const nilDefault = (value, defaultValue) => {
  return value == null ? defaultValue : value
}
const words = (subject, pattern, flags) => {
  const subjectString = coerceToString(subject)
  let patternRegExp
  if (isNil(pattern)) {
    patternRegExp = REGEXP_EXTENDED_ASCII.test(subjectString)
      ? REGEXP_LATIN_WORD
      : REGEXP_WORD
  } else if (pattern instanceof RegExp) {
    patternRegExp = pattern
  } else {
    const flagsString = toString(nilDefault(flags, ""))
    patternRegExp = new RegExp(toString(pattern), flagsString)
  }
  return nilDefault(subjectString.match(patternRegExp), [])
}
const clipNumber = (value, downLimit, upLimit) => {
  if (value <= downLimit) {
    return downLimit
  }
  if (value >= upLimit) {
    return upLimit
  }
  return value
}
const toInteger = (value) => {
  if (value === Infinity) {
    return MAX_SAFE_INTEGER
  }
  if (value === -Infinity) {
    return -MAX_SAFE_INTEGER
  }
  return ~~value
}
const reduceUnescapedString = (string, key) => {
  return string.replace(unescapeCharactersMap[key], key)
}
const removeCombiningMarks = (character, cleanCharacter) => {
  return cleanCharacter
}
let diacriticsMap = null
const getDiacriticsMap = () => {
  if (diacriticsMap !== null) {
    return diacriticsMap
  }
  diacriticsMap = {}
  Object.keys(diacritics).forEach(function (key) {
    const characters = diacritics[key]
    for (let index = 0; index < characters.length; index++) {
      const character = characters[index]
      diacriticsMap[character] = key
    }
  })
  return diacriticsMap
}
const getLatinCharacter = (character) => {
  const characterWithoutDiacritic = getDiacriticsMap()[character]
  return characterWithoutDiacritic ? characterWithoutDiacritic : character
}
const lowerCase = (subject) => {
  const subjectString = coerceToString(subject, "")
  return subjectString.toLowerCase()
}
