import React from "react"
import { IntercomProvider } from "react-use-intercom"
import { onIntercomClosed } from "../services/intercom"
import AuthProvider from "../context/AuthContext/AuthProvider"
import BranchProvider from "../context/BranchContext/BranchProvider"
import CartProvider from "../context/CartContext/CartProvider"
import ProductProvider from "../context/ProductContext/ProductProvider"
import PushNotificationProvider from "../context/PushNotification/PushNotificationProvider"

const RootElementWrapper = ({ element }) => {
  return (
    <PushNotificationProvider>
      <AuthProvider>
        <BranchProvider>
          <CartProvider>
            <IntercomProvider
              appId={process.env.GATSBY_INTERCOM_APP_ID}
              onHide={onIntercomClosed}
            >
              <ProductProvider>{element}</ProductProvider>
            </IntercomProvider>
          </CartProvider>
        </BranchProvider>
      </AuthProvider>
    </PushNotificationProvider>
  )
}

export default RootElementWrapper
