/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"

import AuthContext from "."
import PushNotificationContext from "../PushNotification/PushNotificationContext"

import {
  logout,
  isLoggedIn,
  userLogin,
  userRegister,
} from "../../services/auth"
import { clearGuestCartTracking } from "../../lib/woocommApi"
// import {
//   segmentIdentify,
//   segmentLogin,
//   segmentLogout,
//   segmentRegister,
// } from "../../services/segment"
import { gtmIdentify } from "../../services/googleTagManagerDataLayerTracking"

const AuthProvider = ({ children }) => {
  const [loggedInState, setLoggedInState] = useState(isLoggedIn())
  const { pushNotificationEndpoint } = useContext(PushNotificationContext)

  // Every time the logged in state is updated (ie loaded from localstorage), send the
  // identified user data to Segment
  useEffect(() => {
    if (loggedInState) {
      // segmentIdentify()
      gtmIdentify()
    }
  }, [loggedInState])

  // Check if user login is valid and set global state
  const checkLoggedInState = () => {
    setLoggedInState(isLoggedIn())
  }

  // Just a "register" wrapper. This should actually also perform login
  const performRegister = (
    email,
    password,
    first_name,
    last_name,
    bday,
    opt_in = false,
    account_type,
    business_registration_number,
    business_name,
    contact_number,
    captchaResponse = "",
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {}
  ) => {
    const callback = () => {
      // segmentRegister({
      //   email,
      //   first_name,
      //   last_name,
      //   birthday: bday,
      //   accountType: account_type,
      //   businessRegistrationNumber: business_registration_number,
      //   business_name: business_name,
      //   contact_number: contact_number,
      // })
      performLogin(email, password, setLoading, setApiError, afterCallback)
    }
    userRegister(
      email,
      password,
      first_name,
      last_name,
      bday,
      opt_in,
      account_type,
      business_registration_number,
      business_name,
      contact_number,
      captchaResponse,
      setLoading,
      setApiError,
      callback
    )
  }

  // Just a "userLogin" wrapper that also updates the global state
  const performLogin = (
    email,
    password,
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {}
  ) => {
    const callback = async (responseData) => {
      await afterCallback()
      setLoggedInState(true)
      // segmentLogin({ email })
      if (pushNotificationEndpoint) {
        clearGuestCartTracking(pushNotificationEndpoint).catch((error) => {
          console.error("error while clearing guest cart data", error)
        })
      }
    }
    return userLogin(email, password, setLoading, setApiError, callback)
  }

  // Perform a logout, update the global logged in state and navigate away
  const performLogout = (navigateLink = null) => {
    // segmentLogout()
    logout()
    setLoggedInState(false)
    if (navigateLink !== null) {
      navigate(navigateLink)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loggedInState,
        checkLoggedInState,
        performRegister,
        performLogin,
        performLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
