import React, { useState, useEffect, useLayoutEffect, useContext } from "react"

import { nanoid } from "nanoid"

import CartContext from "../CartContext"
import PushNotificationContext from "./PushNotificationContext"

const subscriptionUrl = `${process.env.GATSBY_WP_URL}/wp-json/wc_api_push/v1/subscribe`
const isBrowser = () => typeof window !== "undefined"

const saveSubscriptionToServer = async (subscription) => {
  try {
    const subscribePayload = JSON.stringify({ subscription })
    const subscribeResponse = await fetch(subscriptionUrl, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: subscribePayload,
    })
    if (subscribeResponse.status === 201) {
      return true
    }
    return false
  } catch (error) {
    console.error("Push notification subscription failed with error:", error)
    return false
  }
}

const requestNotificationPermission = async (pushAnalyticsId) => {
  if (Notification?.permission === "granted") {
    return true
  } else if (Notification?.permission !== "denied") {
    gaPostNotifPermissionRequestBuiltIn(
      process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
      pushAnalyticsId
    )
    const permission = await Notification?.requestPermission?.()
    if (permission === "granted") {
      gaPostNotifPermissionRequestBuiltInAccepted(
        process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
        pushAnalyticsId
      )
      return true
    }
    gaPostNotifPermissionRequestBuiltInRejected(
      process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
      pushAnalyticsId
    )
  }
  return false
}

const getPushSupported = () => {
  if (typeof window !== `undefined`) {
    if (typeof navigator !== `undefined`) {
      if ("PushManager" in window) {
        return true
      }
    }
  }
  return false
}

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
/*
 * function: emitGoogleAnalyticsEvent
 * send a custom event to Google Analytics
 *
 *  tracking_id (Required): Google Analytics tracking ID for this account
 *  analytics_id (Required): Unique ID for the current user
 *  event_name (Required): Name of the event in Google Analytics Dashboard
 *  event_category (Optional): Name of a group of events in Google Analytics Dashboard
 *  event_label (Optional): Longer description of the event in Google Analytics Dashboard
 */
const emitGoogleAnalyticsEvent = ({
  tracking_id,
  analytics_id,
  event_name,
  event_category,
  event_label,
}) => {
  const payloadData = {
    // Version Number
    v: 1,
    // Client ID
    cid: analytics_id,
    // Tracking ID
    tid: tracking_id,
    // Hit Type
    t: "event",
    // Event Category
    ec: event_category,
    // Event Action
    ea: event_name,
    // Event Label
    el: event_label,
  }

  const payloadString = Object.keys(payloadData)
    .filter((analyticsKey) => payloadData[analyticsKey])
    .map(
      (analyticsKey) =>
        analyticsKey + "=" + encodeURIComponent(payloadData[analyticsKey])
    )
    .join("&")

  fetch("https://www.google-analytics.com/collect", {
    method: "post",
    body: payloadString,
  }).catch((err) => console.error("GA Fetch error", err))
}

// GOOGLE ANALYTICS --- Push notification permission request. Custom popup shown
export const gaPostNotifPermissionRequestCustom = (trackingId, analyticsId) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Custom Push Permission Popup Shown",
    event_label: "The custom push permission popup was shown to a customer",
  })

// GOOGLE ANALYTICS --- Push notification permission request. Custom popup rejected by user
export const gaPostNotifPermissionRequestCustomRejected = (
  trackingId,
  analyticsId
) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Custom Push Permission Popup Rejected",
    event_label: "The custom push permission popup was rejected by a customer",
  })

// GOOGLE ANALYTICS --- Push notification permission request. Custom popup accepted by user
export const gaPostNotifPermissionRequestCustomAccepted = (
  trackingId,
  analyticsId
) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Custom Push Permission Popup Accepted",
    event_label: "The custom push permission popup was accepted by a customer",
  })

// GOOGLE ANALYTICS --- Push notification permission request. Built-In popup shown
export const gaPostNotifPermissionRequestBuiltIn = (trackingId, analyticsId) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Built-In Push Permission Popup Started",
    event_label:
      "The built-in push permission popup process was started by the code",
  })

// GOOGLE ANALYTICS --- Push notification permission request. Built-In popup rejected by user
export const gaPostNotifPermissionRequestBuiltInRejected = (
  trackingId,
  analyticsId
) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Built-In Push Permission Popup Rejected",
    event_label:
      "The built-in push permission popup was rejected by a customer",
  })

// GOOGLE ANALYTICS --- Push notification permission request. Built-In popup accepted by user
export const gaPostNotifPermissionRequestBuiltInAccepted = (
  trackingId,
  analyticsId
) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Built-In Push Permission Popup Accepted",
    event_label:
      "The built-in push permission popup was accepted by a customer",
  })

// GOOGLE ANALYTICS --- Push notification subscription completed
export const gaPostNotifSubscribeSuccess = (trackingId, analyticsId) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Push Notification Subscription Creation Success",
    event_label:
      "The browser has successfully subscribed for push notifications",
  })

// GOOGLE ANALYTICS --- Push notification subscription failed
export const gaPostNotifSubscribeFail = (trackingId, analyticsId) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Push Notification Subscription Creation Failure",
    event_label: "The browser has failed to subscribe for push notifications",
  })

// GOOGLE ANALYTICS --- Push notification subscription saved to backend
export const gaPostNotifSubscribeSaveSuccess = (trackingId, analyticsId) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Push Notification Subscription Save To Backend Success",
    event_label:
      "The browser has successfully saved a push notifications subscription to the backend",
  })

// GOOGLE ANALYTICS --- Push notification subscription failed to save to backend
export const gaPostNotifSubscribeSaveFail = (trackingId, analyticsId) =>
  emitGoogleAnalyticsEvent({
    tracking_id: trackingId,
    analytics_id: analyticsId,
    event_category: "Push Notifications",
    event_name: "Push Notification Subscription Save To Backend Failure",
    event_label:
      "The browser has failed to save a push notifications subscription to the backend",
  })

const showPopupAfterThisManyNavigates = 2

const PushNotificationProvider = ({ children }) => {
  const { cartDrawerOpen } = useContext(CartContext)

  const [subscribeProcessCompleted, setSubscribeProcessCompleted] =
    useState(false)
  const [subscriptionSavedState, setSubscriptionSavedState] = useState(() => {
    const isPushSupported_temp = getPushSupported()
    if (!isPushSupported_temp) {
      return "NOT_SUPPORTED"
    } else {
      let savedState = isBrowser() && localStorage.getItem("PUSH_STATE_V1")
      if (!savedState) {
        savedState = "SUPPORTED"
      }
      return savedState
    }
  })

  const [
    canShowCustomPushPermissionRequest,
    setCanShowCustomPushPermissionRequest,
  ] = useState(null)

  const [
    customPushPermissionRequestTriggered,
    setCustomPushPermissionRequestTriggered,
  ] = useState(null)

  const [
    showCustomPushPermissionRequestPopup,
    setShowCustomPushPermissionRequestPopup,
  ] = useState(null)

  const [locationCounter, setLocationCounter] = useState(0)
  const [pushNotificationLocation, setPushNotificationLocation] = useState(null)
  const [pushNotificationEndpoint, setPushNotificationEndpoint] = useState(
    () => {
      const savedEndpoint =
        isBrowser() && localStorage.getItem("PUSH_ENDPOINT_V1")
      if (savedEndpoint) {
        return savedEndpoint
      }
      return null
    }
  )
  const [pushAnalyticsId, setPushAnalyticsId] = useState(null)

  useLayoutEffect(() => {
    let mounted = true
    let upgradeNeeded = false
    function indexedDbInit() {
      document.removeEventListener("scroll", indexedDbInit)
      document.removeEventListener("mousedown", indexedDbInit)
      document.removeEventListener("mousemove", indexedDbInit)
      document.removeEventListener("touchstart", indexedDbInit)
      document.removeEventListener("keydown", indexedDbInit)

      if (typeof indexedDB != "undefined") {
        const dbName = "push_notification_data"

        const openRequest = indexedDB.open(dbName, 1)
        if (openRequest) {
          openRequest.onerror = (event) => {
            console.error(
              `${dbName} Database open error: ` + event.target.errorCode
            )
          }
          openRequest.onsuccess = (event) => {
            if (!upgradeNeeded) {
              const db = event?.target?.result
              const transaction = db?.transaction?.(["analyticsId"])
              const objectStore = transaction?.objectStore?.("analyticsId")
              const getRequest = objectStore?.get?.(1)
              getRequest.onerror = function (event) {
                console.error(
                  `${dbName} Database read error: ` + event?.target?.errorCode
                )
              }
              getRequest.onsuccess = () => {
                const analyticsId = getRequest?.result
                if (mounted && analyticsId) {
                  setPushAnalyticsId(analyticsId)
                }
              }
            }
          }
          openRequest.onupgradeneeded = (event) => {
            upgradeNeeded = true
            const db = event?.target?.result

            if (db) {
              const objectStore = db?.createObjectStore?.("analyticsId", {
                autoIncrement: true,
              })
              if (objectStore) {
                // Use transaction oncomplete to make sure the objectStore creation is
                // finished before adding data into it.
                objectStore.transaction.oncomplete = () => {
                  // Store values in the newly created objectStore.
                  const userObjectStore = db
                    .transaction("analyticsId", "readwrite")
                    .objectStore("analyticsId")
                  if (userObjectStore) {
                    const analyticsId = nanoid()
                    const addRequest = userObjectStore.add(analyticsId)
                    addRequest.onsuccess = () => {
                      if (mounted && addRequest) {
                        setPushAnalyticsId(analyticsId)
                      }
                    }
                    addRequest.onerror = (event) => {
                      console.error(
                        `${dbName} Database write error: ` +
                          event.target.errorCode
                      )
                    }
                  }
                }
              }
            }
          }

          if (typeof localStorage == "undefined") {
            return null
          }
          let savedAnalyticsId =
            isBrowser() && localStorage.getItem("PUSH_ANALYTICS_V1")
          if (!savedAnalyticsId) {
            savedAnalyticsId = nanoid()
            isBrowser() &&
              localStorage.setItem("PUSH_ANALYTICS_V1", savedAnalyticsId)
          }
        }
      }
    }

    document.addEventListener("scroll", indexedDbInit)
    document.addEventListener("mousedown", indexedDbInit)
    document.addEventListener("mousemove", indexedDbInit)
    document.addEventListener("touchstart", indexedDbInit)
    document.addEventListener("keydown", indexedDbInit)

    return () => {
      indexedDbInit()
      mounted = false
    }
  }, [])

  // fire analytics if the popup becomes visible
  useEffect(() => {
    if (showCustomPushPermissionRequestPopup) {
      gaPostNotifPermissionRequestCustom(
        process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
        pushAnalyticsId
      )
    }
  }, [showCustomPushPermissionRequestPopup])

  // Show push notification popup after first navigation
  useEffect(() => {
    if (locationCounter >= showPopupAfterThisManyNavigates) {
      triggerShowCustomPushPermissionRequest()
    }
  }, [locationCounter])

  // Count navigations
  useEffect(() => {
    if (
      pushNotificationLocation &&
      locationCounter < showPopupAfterThisManyNavigates
    ) {
      setLocationCounter(locationCounter + 1)
    }
  }, [pushNotificationLocation])

  // Make sure that the popup is closed if the subscribe process has been completed
  useEffect(() => {
    if (subscribeProcessCompleted) {
      setShowCustomPushPermissionRequestPopup(false)
    }
  }, [subscribeProcessCompleted])

  // Open custom push permission popup when cart opens
  useEffect(() => {
    if (cartDrawerOpen) {
      triggerShowCustomPushPermissionRequest()
    }
  }, [cartDrawerOpen])

  // always update sw
  useEffect(() => {
    if (getPushSupported() && "serviceWorker" in navigator) {
      navigator?.serviceWorker?.ready?.then?.((swRegistration) => {
        swRegistration?.update()
      })
    }
  }, [])

  // FSM
  useEffect(() => {
    if (subscriptionSavedState) {
      isBrowser() &&
        localStorage.setItem("PUSH_STATE_V1", subscriptionSavedState)
      if (!subscribeProcessCompleted) {
        switch (subscriptionSavedState) {
          // browser doesn't support push notifications. Result of first state in FSM
          case "SUPPORTED": {
            setCanShowCustomPushPermissionRequest(true)
            break
          }

          case "SERVICE_WORKER_ERROR": // user enabled push notifications, but a service worker error occured
          case "SUBSCRIBE_ERROR": // user enabled push notifications, an error occured while making the browser's subscribe request
          case "UPLOAD_ERROR": // user enabled push notifications, an error occured while uploading the subscriber to our backend
          case "SUBSCRIBE_FLOW_ERROR": // user enabled push notifications, an error occured somewhere in the subscribe flow
          case "ACCEPTED": {
            // user accepted custom push notification permission request
            setShowCustomPushPermissionRequestPopup(false)
            createSubscription()
            break
          }
          case "NOT_SUPPORTED": // browser doesn't support push notifications. Result of first state in FSM
          case "REJECTED": // user rejected custom push notification permission request
          case "ENABLED": // user accepted system push notification permission request
          case "DISABLED": // user rejected system push notification permission request. This isn't supposed to happen
          default: {
            setSubscribeProcessCompleted(true)
            break
          }
        }
      }
    }
  }, [subscriptionSavedState])

  // Handles the conditions for opening the custom permission popup
  useEffect(() => {
    if (!subscribeProcessCompleted) {
      if (
        customPushPermissionRequestTriggered &&
        canShowCustomPushPermissionRequest
      ) {
        setShowCustomPushPermissionRequestPopup(true)
      }
    }
  }, [customPushPermissionRequestTriggered, canShowCustomPushPermissionRequest])

  // convenience function for when a user accepts our custom notification request
  const userAcceptedNotifications = () => {
    gaPostNotifPermissionRequestCustomAccepted(
      process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
      pushAnalyticsId
    )
    userNotificationsPermissionRequestResult("ACCEPTED")
  }

  // convenience function for when a user rejects our custom notification request
  const userRejectedNotifications = () => {
    gaPostNotifPermissionRequestCustomRejected(
      process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
      pushAnalyticsId
    )
    userNotificationsPermissionRequestResult("REJECTED")
  }

  // Save the result of the custom notification request
  const userNotificationsPermissionRequestResult = (newState) => {
    setShowCustomPushPermissionRequestPopup(false)
    setSubscriptionSavedState(newState)
  }

  // convenience function to trigger opening the custom notification request
  const triggerShowCustomPushPermissionRequest = () => {
    // setCustomPushPermissionRequestTriggered(true)
    setCustomPushPermissionRequestTriggered(false)
  }

  const createSubscription = async () => {
    if (subscribeProcessCompleted) {
      return
    } else {
      const isPushSupported = getPushSupported()
      if (!isPushSupported) {
        setSubscriptionSavedState("NOT_SUPPORTED")
        return
      } else {
        if (!("serviceWorker" in navigator)) {
          setSubscriptionSavedState("SERVICE_WORKER_ERROR")
          return
        } else {
          const hasPermission = await requestNotificationPermission(
            pushAnalyticsId
          )
          if (!hasPermission) {
            setSubscriptionSavedState("DISABLED")
            return
          } else {
            navigator.serviceWorker.ready
              .then(async (swRegistration) => {
                const subscriber = {
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(
                    process.env.GATSBY_VAPID_PUBLIC_KEY
                  ),
                }
                const subscribeResponse =
                  await swRegistration.pushManager.subscribe(subscriber)
                if (!subscribeResponse) {
                  gaPostNotifSubscribeFail(
                    process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
                    pushAnalyticsId
                  )
                  setSubscriptionSavedState("SUBSCRIBE_ERROR")
                  return
                } else {
                  gaPostNotifSubscribeSuccess(
                    process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
                    pushAnalyticsId
                  )
                  const uploadSubResponse = await saveSubscriptionToServer(
                    subscribeResponse
                  )
                  if (!uploadSubResponse) {
                    gaPostNotifSubscribeSaveFail(
                      process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
                      pushAnalyticsId
                    )
                    console.error("A Push subscription error occured")
                    setSubscriptionSavedState("UPLOAD_ERROR")
                    return
                  } else {
                    gaPostNotifSubscribeSaveSuccess(
                      process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
                      pushAnalyticsId
                    )
                    setSubscriptionSavedState("ENABLED")
                    if (subscribeResponse?.endpoint) {
                      setPushNotificationEndpoint(subscribeResponse?.endpoint)
                      isBrowser() &&
                        localStorage.setItem(
                          "PUSH_ENDPOINT_V1",
                          subscribeResponse?.endpoint
                        )
                    }
                  }
                }
              })
              .catch((error) => {
                gaPostNotifSubscribeFail(
                  process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
                  pushAnalyticsId
                )
                setSubscriptionSavedState("SUBSCRIBE_FLOW_ERROR")
                return
              })
          }
        }
      }
    }
  }

  return (
    <PushNotificationContext.Provider
      value={{
        userAcceptedNotifications,
        userRejectedNotifications,
        showCustomPushPermissionRequestPopup,
        setPushNotificationLocation,
        pushNotificationEndpoint,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  )
}

export default PushNotificationProvider
